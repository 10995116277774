<template>
  <div id="index">
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  computed: {
    ...mapState(["systemTitle"]),
  },
};
</script>
